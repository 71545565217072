.react-tabs {
  -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
  border-bottom: 1px solid #aaa;
  margin: 0 0 10px;
  padding: 0;
  padding-left: 20px;
}

.react-tabs__tab {
  display: inline-flex;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  cursor: pointer;
  width: 180px;
  height: 54px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border: none;
  justify-content: center;
  align-items: center;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 13px;
  outline: none;
  background-color: transparent;
  user-select: none;
}

.react-tabs__tab--selected {
  background-color: white;
  color: #828894;
}

.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

.react-tabs__tab:focus {
  box-shadow: 0 0 5px hsl(208, 99%, 50%);
  border-color: hsl(208, 99%, 50%);
  outline: none;
}

.react-tabs__tab-panel {
  display: none;
  height: 100%;
}

.react-tabs__tab-panel--selected {
  display: block;
  border: none;
  border-radius: 11px;
}
