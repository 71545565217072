body {
  width: 100%;
  min-height: 100vh;
  padding: 0;
  margin: 0;
  border: none;
}

#root {
  width: 100%;
  min-height: 100vh;
  padding: 0;
  margin: 0;
  border: none;
}

.dx-datagrid-rowsview .dx-row > td {
  overflow: visible;
}

.orders-list .dx-datagrid-rowsview .dx-row > td {
  overflow: hidden;
}